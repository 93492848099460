import Navbar from './Navbar/Navbar';
import './style_Home.css';
import part_1_img from '../Home Images/part-1.png';
import part_2_img from '../Home Images/part-2.png';
import part_3_img from '../Home Images/part-3.png';
import part_4_img from '../Home Images/part-4.png';
import { useTranslation } from 'react-i18next';
import old_img from '../Home Images/old.png';
import { motion } from "framer-motion";
import company_1_img from '../Home Images/company-01.png';
import company_2_img from '../Home Images/company-02.png';
import company_3_img from '../Home Images/company-03.png';
import company_4_img from '../Home Images/company-04.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

function Home() {
    // To Make Language
    const { t, i18n } = useTranslation();

    // const [myProject, setMyProject] = useState(false);
    // console.log(myProject);

    return(
        <>
            {/* <Navbar myActiveProject={myProject}/> */}

            <div className='my-home'>
                <div className='hero'>
                    <div className='left'>
                        {/* Head Of Page */}
                        <motion.h1 style={{fontFamily: 'GE Flow'}} initial={{x: '-100%'}} animate={{x: '0%'}} transition={{duration: 1}}>
                            

                            {t('Explore RTC Company.')}

                            
                        </motion.h1>

                        <motion.div initial={{x: '-100%'}} animate={{x: '0%'}} transition={{duration: 1}} className='btn'>
                            
                            <Link onClick={()=> {
                                // setMyProject(true);
                                // console.log('Ok');
                            }} to='/Our-Projects'>
                                <button>
                                    {t('Our Projects')}
                                    <i className="fa-solid fa-wrench"></i>
                                </button>
                            </Link>

                            <Link to='/Our-Services'>
                                <button className='secound'>
                                    {t('Discover Services')}
                                </button>
                            </Link>
                        </motion.div>
                    </div>
                    <motion.div initial={{x: '100%'}} animate={{x: '0%'}} transition={{duration: 1}} className='right'>
                        <img src={part_1_img}></img>
                        <img src={part_2_img}></img>
                        <img src={part_3_img}></img>
                        <img src={part_4_img}></img>
                    </motion.div>
                </div>

                <div className='social'>
                    <span className='circle'></span>
                    <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='left'>
                        <h5>
                            <span></span>
                            {t('SOCIAL RESPONSIBILITY')}
                        </h5>
                        <h3>
                        {t('Since our inception, we have committed ourselves to Rawae Tzudan Co which is dedicated to participating in all major projects aimed at social and economic development.')}
                            
                        </h3>

                        <p>
                            {t('In addition to our profitability we aim to fulfill our full roles in this society to serve this country in response to something of its beauty and virtues. To the Kingdom of Saudi Arabia and assume our full responsibilities to contribute to the realization of the hopes and aspirations of this unique vision.')}
                        </p>

                        <Link to='/WhoWeAre'>
                            <button className='my-but'>
                                
                                {t('More About Us')}
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </button>
                        </Link>
                    </motion.div>

                    <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='right'>
                        <h1>{t('LEADING GLOBAL BUILDER AND DEVELOPER')}</h1>
                        <img src={old_img}></img>
                    </motion.div>
                </div>

                <div className='clints'>
                    <div className='tow-circle'>
                        
                    </div>
                    
                    <div className='content'>
                        <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='left'>
                                <h5>
                                    <span></span>
                                    {t('OUR CLIENTS, OUR SUCCESS PARTNERS')}
                                </h5>
                            </motion.div>

                            <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='right'>
                                <h1>{t('Trusted by world leading companies')}</h1>
                            </motion.div>
                    </div>
                    
                    <div className='logos'>
                        <motion.img src={company_1_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.3}} />
                        <motion.img src={company_2_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.6}}/>
                        <motion.img src={company_3_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.9}}/>
                        <motion.img src={company_4_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 1.2}}/>
                    </div>

                    <span className='circle'></span>
                </div>
            </div>    
        </>
    );
};


export default Home;