import './style_ThirdProject.css';
import MECHANICALCONSTRUCTION_img from '../../../Home Images/MECHANICALCONSTRUCTION.png';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import inside_MECHANICAL_img from '../../../Home Images/MECHANICAL.png';

function ThirdProject() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='third-project'>
                <div className='my-hero'>
                    <div className='my-img'>
                        <img src={MECHANICALCONSTRUCTION_img}/>
                        
                    </div>
                    <div className='content'>
                        

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}>{t('MECHANICAL CONSTRUCTION')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Projects')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('MECHANICAL CONSTRUCTION')}</h5>
                            </div>
                        </div>

                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1}}>
                            {t('Mechanical Construction is an integral part of our business, and is backed by experienced professionals having long construction projects experience. We have successfully completed number of projects of critical nature and execution timeline for customers like SAUDI ELECTRICITY COMPANY.And our experienced & competent team along with range of equipment required for carrying out mechanical construction works in an orderly manner.')}
                        </motion.p>
                        
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    <div className='blok'>
                        <div className='left'>
                            <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('All Services')}</motion.h1>

                            
                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}} className='services'>
                                <i className="fa-solid fa-circle"></i>
                                <h3>{t('EQUIPMENTERECTION')}</h3>
                            </motion.div>

                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}}>
                                <p>-{t('Piping around the equipment')}</p>
                                <br />
                                <p>-{t('Valves, instruments & package equipment')}</p>
                                <br />
                                <p>-{t('Fire hydrant circuits')}</p>
                                <br />
                                <p>-{t('Pumps & turbines, compressor')}</p>
                                <br />
                                <p>-{t('Steel structure erection')}</p>
                                <br />
                                <p>-{t('Heavy lifting')}</p>
                                <br />
                                <p>-{t('Industrial Building')}</p>
                            </motion.div>
                            
                        </div>

                        

                        <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}} className='right'>
                            <img src={inside_MECHANICAL_img}/>
                        </motion.div>
                    </div>

                </div>
            </div>
        </>
    );
};


export default ThirdProject;