import { useEffect, useState } from 'react';
import './style_Navbar.css';
import { Link } from 'react-router-dom';
import { color, motion } from "framer-motion";
import logo_img from '../../Home Images/Logo.png';
import { useTranslation } from 'react-i18next';
import '../../index.css';

function Navbar({myActiveProject}) {
    //console.log(myActiveProject);

    const [scrollHeight, setScrollHeight] = useState(0);
    //console.log(scrollHeight);

    const [isNavShow, setIsNavShow] = useState(true);
    //console.log(isNavShow);

    const [visibleNav, setVisibleNav] = useState(false);
    //console.log(visibleNav);

    const [isEnglish, setIsEnglish] = useState(true);
    //console.log(isEnglish);

    // To Make Language
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('en');
    //console.log(language);
    




      useEffect(() => {
        const direction = language === 'ar' ? 'rtl' : 'ltr';
        document.documentElement.dir = direction;
    
        const styleSheet = document.styleSheets[0]; // [1] becouse ('./style_Navbar.css';) ==> is [0] - And (import '../../index.css';) ==> is [1]
        if (styleSheet.cssRules.length > 0) {
          styleSheet.deleteRule(0);
        }
        styleSheet.insertRule(`* { direction: ${direction}; }`, 0);
      }, [language]);



      useEffect(()=> {

        const mediaQuery = window.matchMedia('(min-width: 481px)');

        // Function to handle the media query change
        const handleMediaQueryChange = (event) => {
        if (event.matches) {
            // in large screen
            //console.log('Gooddd');

            if(language === 'en') {
                setIsEnglish(true);
                //console.log('English');

            } else if(language === 'ar') {
                setIsEnglish(false);
                //console.log('Not English');
            }

            

            } else {
                // in small screen
                //console.log('Baddd');
                setIsEnglish(true);
            }
        };

        // Check the initial state
        if (mediaQuery.matches) {
            //console.log('Good');

        } else {
            //console.log('Bad');
        }

        // Add the event listener
        mediaQuery.addEventListener('change', handleMediaQueryChange);

        // Cleanup function to remove the event listener
        return () => {
        mediaQuery.removeEventListener('change', handleMediaQueryChange);
        };

    }, [isEnglish, language]);




      const toggleLanguage = () => {

        const newLanguage = language === 'en' ? 'ar' : 'en';
        setLanguage(newLanguage);
        
        i18n.changeLanguage(newLanguage);
      };


/*
// To Make Nav Bar Show And Hideen
         
useEffect(() => {
    const handleScroll = () => {
      const height = window.scrollY;
      setScrollHeight(height);
      if (height < 60) { 
          //console.log('iam Form 0');
          setIsNavShow(false);

          setTimeout(() => {
              setVisibleNav(false);
          }, 500);

      } else if (height > 60) {
          //console.log('iam Form 60');
          setIsNavShow(true);

          setTimeout(() => {
              setVisibleNav(true);
          }, 500);
      };

     
    };

    // Add event listener to listen for scroll events
    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isNavShow, visibleNav]); // Empty dependency array to ensure effect runs only once

*/


  /* 
        For Nav In Small Screen
*/
const [isSideBarShow, setIsSideBarShow] = useState(false);
const [isSideBarShowInArabic, setIsSideBarShowInArabic] = useState(false);
//console.log(isSideBarShow);


/* 

        For Click Link, Color Will Change
*/
const [homeAcitve, setHomeActive] = useState(true);
const [whoWeAreAcive, setwhoWeAreActive] = useState(false);
const [ourProjects, setOurProjects] = useState(false);
const [ourServices, setOurServices] = useState(false);
const [ourPartner, setOurPartner] = useState(false);

//console.log(isAcive);

    return(
        <>
        <div>
        

            {/* <div className='Navbar' style={isNavShow === false ? {top: '-100px', transition: '0.3s'} : {top: '0px', transition: '0.3s'}, {direction: 'ltr'}}> */}
            <div className='Navbar' style={{
                // Ternary Operator
                top: !isNavShow ? '-100px' : '0px',
                transition: !isNavShow ? '0.3s' : '0.3s',

                //Normal Style
                //direction: 'ltr'
            }}>


                {/* Fixed Nav When Scroll in larg screen */}
                <div className='fix-nav'>
                    <div className='left'>
                        
                        <img src={logo_img}></img>
                        
                    </div>
                    <div className='center'>
                        
                        <Link onClick={()=> {
                            setHomeActive(true);
                            setwhoWeAreActive(false);
                            setOurProjects(false);
                            setOurServices(false);
                            setOurPartner(false);
                        }} style={homeAcitve === true ? {color: '#EF4629'} : {color: '#848168'}} to='/'>{t('Home')}</Link>

                        <i className="fa-solid fa-caret-down"></i>

                        <Link onClick={()=> {
                            setwhoWeAreActive(true);
                            setHomeActive(false);
                            setOurProjects(false);
                            setOurServices(false);
                            setOurPartner(false);
                        }} style={whoWeAreAcive === true ? {color: '#EF4629'} : {color: '#848168'}} to='/WhoWeAre'>{t('Who We Are')}</Link>


                        <i className="fa-solid fa-caret-down"></i>

                        <Link onClick={()=> {
                            setOurProjects(true);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                            setOurServices(false);
                            setOurPartner(false);
                        }} style={ourProjects === true ? {color: '#EF4629'} : {color: '#848168'}} to='/Our-Projects'>{t('Our Projects')}</Link>

                        <i className="fa-solid fa-caret-down"></i>

                        <Link onClick={()=> {
                            setOurServices(true);
                            setOurProjects(false);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                            setOurPartner(false);
                        }} style={ourServices === true ? {color: '#EF4629'} : {color: '#848168'}} to='/Our-Services'>{t('Our Servecies')}</Link>

                        <i className="fa-solid fa-caret-down"></i>

                        <Link onClick={()=> {
                            setOurPartner(true);
                            setOurServices(false);
                            setOurProjects(false);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                        }} style={ourPartner === true ? {color: '#EF4629'} : {color: '#848168'}} to='/Our-Partner'>{t('Our Success Partners')}</Link>

                        <i style={language === 'ar' ? {transform: 'translateX(528px)'} : null} className="fa-solid fa-caret-down"></i>
                        
                        <span onClick={()=> toggleLanguage()} className='lang'>{i18n.language === 'en' ? 'Ar' : 'En'}</span>
                        
                    </div>
                    
                </div>

            </div>






            <div className='Navbar-for-small-screen' style={{
                // Ternary Operator
                top: !isNavShow ? '-100px' : '0px',
                transition: !isNavShow ? '0.3s' : '0.3s',

                //Normal Style
                //direction: 'ltr'
            }}>
               
                {/* Fixed Nav When Scroll in Small Screen */}
                <div className='fix-nav'>
                    <div className='left'>
                        <img src={logo_img}></img>
                    </div>
                    <div className='right'>
                        <i className="fa-solid fa-bars" onClick={()=> isEnglish === true ? setIsSideBarShow(!isSideBarShow) : setIsSideBarShowInArabic(!isSideBarShowInArabic)}></i>
                    </div>
                </div>

            </div>




            {/* Start my Side Nav To Small Screen */}
            {/* <motion.div className='side-bar' style={isSideBarShow ? {left: '100%', transition: '0.5s'} : {left: '190%', transition: '0.5s'}}> */}
                
            <motion.div className='side-bar' style={{
                left: isSideBarShow ? '100%' : '190%',
                transition: isSideBarShow ? '0.5s' : '0.5s',

                right: isSideBarShowInArabic ? '40%' : '70%',
                transition: isSideBarShowInArabic ? '0.5s' : '0.5s',
            }}>
                
                <div className='my-side'>
                    
                    <div className='links'>
                            <a>
                                <i className="fa-brands fa-facebook-f"></i>
                            </a>
                            <a>
                                <i className="fa-brands fa-x-twitter"></i>
                            </a>
                            <a>
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                            <a>
                                <i className="fa-brands fa-linkedin-in"></i>
                            </a>                                                    
                    </div>

                    <div className='pages'> 
                    <Link onClick={()=> {
                        setIsSideBarShow(false);

                            setHomeActive(true);
                            setwhoWeAreActive(false);
                            setOurProjects(false);
                            setOurServices(false);
                            setOurPartner(false);
                    }} style={homeAcitve === true ? {color: '#EF4629'} : {color: '#848168'}} to='/'>{t('Home')}</Link>
                        
                    <Link onClick={()=> {
                        setIsSideBarShow(false);

                            setwhoWeAreActive(true);
                            setHomeActive(false);
                            setOurProjects(false);
                            setOurServices(false);
                            setOurPartner(false);
                    }} style={whoWeAreAcive === true ? {color: '#EF4629'} : {color: '#848168'}} to='/WhoWeAre'>{t('Who We Are')}</Link>

                    <Link onClick={()=> {
                        setIsSideBarShow(false);

                            setOurProjects(true);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                            setOurServices(false);
                            setOurPartner(false);
                    }} style={ourProjects === true ? {color: '#EF4629'} : {color: '#848168'}} to='/Our-Projects'>{t('Our Projects')}</Link>

                    <Link onClick={()=> {
                        setIsSideBarShow(false);

                            setOurServices(true);
                            setOurProjects(false);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                            setOurPartner(false);
                    }} style={ourServices === true ? {color: '#EF4629'} : {color: '#848168'}} to='/Our-Services'>{t('Our Servecies')}</Link>

                    <Link onClick={()=> {
                        setIsSideBarShow(false);

                            setOurPartner(true);
                            setOurServices(false);
                            setOurProjects(false);
                            setHomeActive(false);
                            setwhoWeAreActive(false);
                    }} style={ourPartner === true ? {color: '#EF4629'} : {color: '#848168'}} to='Our-Partner'>{t('Our Success Partners')}</Link>
                        
                        
                        <span onClick={()=> {
                            toggleLanguage();
                            setIsSideBarShow(false);

                        }} className='lang'>{i18n.language === 'en' ? 'Ar' : 'En'}</span>
                    </div>
                </div>

            </motion.div>
        </div>
        </>
    );
};


export default Navbar;