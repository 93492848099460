import './style_FirstProject.css';
import civil_img from '../../Home Images/CIVILConstruction.png';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import inside_civil_img from '../../Home Images/inside civil.png';

function FirstProjects() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='first-project'>
                <div className='my-hero'>
                    <div className='my-img'>
                        <img src={civil_img}/>
                        
                    </div>
                    <div className='content'>
                        

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}>{t('CIVIL Construction')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Projects')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('CIVIL Construction')}</h5>
                            </div>
                        </div>

                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1}}>
                            {t('By virtue of engagement & managing large-scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specifications for our customers. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.')}
                        </motion.p>
                        
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    <div className='blok'>
                        <div className='left'>
                            <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('All Services')}</motion.h1>

                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 0.5}} className='services'>
                                <i className="fa-solid fa-circle"></i>
                                <h3>{t('SURVEYING')}</h3>
                            </motion.div>

                            <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 0.5}}>-{t('Earthwork')}</motion.p>

                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}} className='services'>
                                <i className="fa-solid fa-circle"></i>
                                <h3>{t('FOUNDATION')}</h3>
                            </motion.div>

                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}}>
                                <p>-{t('Super Structure')}</p>
                                <p>-{t('Industrial Building')}</p>
                                <p>-{t('Architectural Finish')}</p>
                                <p>-{t('Pipe Rack')}</p>
                                <p>-{t('Warehouse')}</p>
                                <p>-{t('Plant Road')}</p>
                                <p>-{t('Pavement')}</p>
                                <p>-{t('Landscaping Work')}</p>
                            </motion.div>
                            
                        </div>

                        

                        <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}} className='right'>
                            <img src={inside_civil_img}/>
                        </motion.div>
                    </div>

                </div>
            </div>
        </>
    );
};


export default FirstProjects;