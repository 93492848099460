import './style_Footer.css';
import logo_img from '../../Home Images/Logo.png';

function Footer() {
    return(
        <>
            <div className='my-footer'>
                <img src={logo_img} />

                <div className='my-links'>
                    <a>
                        <i className="fa-brands fa-facebook-f"></i>
                    </a>
                    <a>
                        <i className="fa-brands fa-x-twitter"></i>
                    </a>
                    <a>
                        <i className="fa-brands fa-instagram"></i>
                    </a>
                    <a>
                        <i className="fa-brands fa-linkedin-in"></i>
                    </a>
                </div>
            </div>
        </>
    );
};


export default Footer;