import './style_WhoWeAre.css';
import about_img from '../../Home Images/WhoWeAre.png';
import about_small_img from '../../Home Images/small_about.png';
import vector_img from '../../Home Images/vector-about.png';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

function WhoWeAre() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='who-we-are'>
                <div className='my-hero'>
                    <div className='my-img'>
                        <img src={about_img}/>
                        
                    </div>
                    <div className='content'>
                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1}}>
                            {t('RTC (with CR: 1010273881 ) Since its inception, the company has been a professional company in the field of electrical contracting, planning, installation and maintenance, and has been able to achieve a range of qualitative achievements at the level of activities as well as the number of customers acquired in Both government and private sectors, This success is due to the policy adopted and based on the overall quality standards and customer satisfaction.')}
                        </motion.p>

                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1, delay: 0.5}}>
                            {t('The company has completed a large number of projects related to the field of electrical contracting. They have been able to obtain prestigious classifications, which were made by major projects in the Kingdom of Saudi Arabia. We are proud of our unique human resources, discipline, and strict responsibility within the framework of coordinated and integrated teams.')}
                        </motion.p>

                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1, delay: 1}}>
                            {t('The company has a large number of managers, engineers, technicians and workers of various nationalities with a focus on the Saudi energies, who have accumulated long experiences in the work related to electrical contracting Which contributed to enhancing their ability to complete the work according to the quality standards as is internationally recognized.')}
                        </motion.p>

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}>{t('About Us')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Who We Are')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('About Us')}</h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    <div className='blok'>
                        <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('VISION')}</motion.h1>
                        <div className='MSG'>
                            <motion.i initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 0.5}} className="fa-solid fa-layer-group"></motion.i>
                            
                            <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}}>
                                {t('Since our inception in the company in the year 2009, we designed to achieve our vision of comprehensive and built upon our strategic goals, and reflected in building the foundations of an integrated administrative and technical company, and available on the potential promising investment to get ratings that suit their ambitions and efforts, To become a primary reference for customers who wish to benefit from high quality services.')}
                            </motion.p>
                        </div>
                    </div>

                    <div className='blok'>
                        <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('MESSAGE')}</motion.h1>
                        
                        <div className='MSG'>
                            <motion.i initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 0.5}} className="fa-solid fa-envelope"></motion.i>
                            
                            <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}}>
                                 {t('We carry in the company a message of economic and social foundations. We work hard to spread the values of quality in their administrative and technical dimensions within the companies and institutions that operate in the electrical contracting sector. In our social mission, we seek to support innovation and contribute to achieving a society that is more open to the Aspirations of citizens.')}
                            </motion.p>
                            <motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1.5}} src={vector_img} />
                        </div>
                    </div> 

                    <div className='blok'>
                        
                        <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('OBJECTIVES')}</motion.h1>
                        <div className='MSG last'>
                            <motion.i initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 0.5}} className="fa-solid fa-circle-check"></motion.i>
                            
                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}} className='obj'>
                                <h4>{t('We aim to achieve a set of goals at the near and long term, and are reflected in :')}</h4>
                                <p>{t('- Achieve the highest quality standards and obtain international recognition certificates.')}</p>
                                <p>{t('- Achieve the best levels of communication with customers by developing the means available.')}</p> 
                                <p>{t('- Training engineers , technicians , administrators and workers .')}</p>
                                <p>{t('- Spread our branches in the rest of the kingdom in the hope of expansion in the gulf region .')}</p>

                                
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default WhoWeAre;