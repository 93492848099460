// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      // Navbar
      "Home": "Home",
      "Who We Are": "Who We Are",
      "Our Projects": "Our Projects",
      "Our Servecies": "Our Servecies",
      "Our Success Partners": "Our Success Partners",

      // (Home) Hero Section
      "Discover Services": "Discover Services",
      "Explore RTC Company.": "Explore RTC Company.",

      // (Home) SOCIAL RESPONSIBILITY
      "SOCIAL RESPONSIBILITY": "SOCIAL RESPONSIBILITY",
      "Since our inception, we have committed ourselves to Rawae Tzudan Co which is dedicated to participating in all major projects aimed at social and economic development.":
       "Since our inception, we have committed ourselves to Rawae Tzudan Co which is dedicated to participating in all major projects aimed at social and economic development.",
      
       "In addition to our profitability we aim to fulfill our full roles in this society to serve this country in response to something of its beauty and virtues. To the Kingdom of Saudi Arabia and assume our full responsibilities to contribute to the realization of the hopes and aspirations of this unique vision.": 
       "In addition to our profitability we aim to fulfill our full roles in this society to serve this country in response to something of its beauty and virtues. To the Kingdom of Saudi Arabia and assume our full responsibilities to contribute to the realization of the hopes and aspirations of this unique vision.",

      "More About Us": "More About Us",

      "LEADING GLOBAL BUILDER AND DEVELOPER": "LEADING GLOBAL BUILDER AND DEVELOPER",

      // (Home) clints
      "OUR CLIENTS, OUR SUCCESS PARTNERS": "OUR CLIENTS, OUR SUCCESS PARTNERS",
      "Trusted by world leading companies": "Trusted by world leading companies",
    
      // ###############################################
      // (Who We Are) my-hero
      "RTC (with CR: 1010273881 ) Since its inception, the company has been a professional company in the field of electrical contracting, planning, installation and maintenance, and has been able to achieve a range of qualitative achievements at the level of activities as well as the number of customers acquired in Both government and private sectors, This success is due to the policy adopted and based on the overall quality standards and customer satisfaction.": 
      "RTC (with CR: 1010273881 ) Since its inception, the company has been a professional company in the field of electrical contracting, planning, installation and maintenance, and has been able to achieve a range of qualitative achievements at the level of activities as well as the number of customers acquired in Both government and private sectors, This success is due to the policy adopted and based on the overall quality standards and customer satisfaction.",
      
      "The company has completed a large number of projects related to the field of electrical contracting. They have been able to obtain prestigious classifications, which were made by major projects in the Kingdom of Saudi Arabia. We are proud of our unique human resources, discipline, and strict responsibility within the framework of coordinated and integrated teams.": 
      "The company has completed a large number of projects related to the field of electrical contracting. They have been able to obtain prestigious classifications, which were made by major projects in the Kingdom of Saudi Arabia. We are proud of our unique human resources, discipline, and strict responsibility within the framework of coordinated and integrated teams.",
      
      "The company has a large number of managers, engineers, technicians and workers of various nationalities with a focus on the Saudi energies, who have accumulated long experiences in the work related to electrical contracting Which contributed to enhancing their ability to complete the work according to the quality standards as is internationally recognized.": 
      "The company has a large number of managers, engineers, technicians and workers of various nationalities with a focus on the Saudi energies, who have accumulated long experiences in the work related to electrical contracting Which contributed to enhancing their ability to complete the work according to the quality standards as is internationally recognized.",
    
      "About Us": "About Us",
      "Who We Are": "Who We Are",

      // (Who We Are) content
      "VISION": "VISION",

      "Since our inception in the company in the year 2009, we designed to achieve our vision of comprehensive and built upon our strategic goals, and reflected in building the foundations of an integrated administrative and technical company, and available on the potential promising investment to get ratings that suit their ambitions and efforts, To become a primary reference for customers who wish to benefit from high quality services.": 
      "Since our inception in the company in the year 2009, we designed to achieve our vision of comprehensive and built upon our strategic goals, and reflected in building the foundations of an integrated administrative and technical company, and available on the potential promising investment to get ratings that suit their ambitions and efforts, To become a primary reference for customers who wish to benefit from high quality services.",
      
      "MESSAGE": "MESSAGE",

      "We carry in the company a message of economic and social foundations. We work hard to spread the values of quality in their administrative and technical dimensions within the companies and institutions that operate in the electrical contracting sector. In our social mission, we seek to support innovation and contribute to achieving a society that is more open to the Aspirations of citizens.": 
      "We carry in the company a message of economic and social foundations. We work hard to spread the values of quality in their administrative and technical dimensions within the companies and institutions that operate in the electrical contracting sector. In our social mission, we seek to support innovation and contribute to achieving a society that is more open to the Aspirations of citizens.",
    
      "OBJECTIVES": "OBJECTIVES",

      "We aim to achieve a set of goals at the near and long term, and are reflected in :": 
      "We aim to achieve a set of goals at the near and long term, and are reflected in :",
    
      "- Achieve the highest quality standards and obtain international recognition certificates.": 
      "- Achieve the highest quality standards and obtain international recognition certificates.",

      "- Achieve the best levels of communication with customers by developing the means available.": 
      "- Achieve the best levels of communication with customers by developing the means available.",
    
      "- Training engineers , technicians , administrators and workers .": 
      "- Training engineers , technicians , administrators and workers .",

      "- Spread our branches in the rest of the kingdom in the hope of expansion in the gulf region .": 
      "- Spread our branches in the rest of the kingdom in the hope of expansion in the gulf region .",
    
      // ###############################################
      // (Our Projects) Civil Constraction [FirstProjects]
      "CIVIL Construction": "CIVIL Construction",

      "By virtue of engagement & managing large-scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specifications for our customers. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.": 
      "By virtue of engagement & managing large-scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specifications for our customers. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.",
    
      "All Services": "All Services",
      "SURVEYING": "SURVEYING",
      "Earthwork": "Earthwork",

      "FOUNDATION": "FOUNDATION",
      "Super Structure": "Super Structure",
      "Industrial Building": "Industrial Building",
      "Architectural Finish": "Architectural Finish",
      "Pipe Rack": "Pipe Rack",
      "Warehouse": "Warehouse",
      "Plant Road": "Plant Road",
      "Pavement": "Pavement",
      "Landscaping Work": "Landscaping Work",


      // (Our Projects) ELECTRICAL Constraction [SecoundProject]
      "ELECTRICAL & INSTRUMENTATION CONSTRUCTION": "ELECTRICAL & INSTRUMENTATION CONSTRUCTION",
      
      "Commendable reputation in E&I construction has been built over years of successful execution of variety of large-scale projects for major companies in the Kingdom like smart meters project. With our long experience, expertise and highly competent team, we continue to enjoy the trust of our clients in our abilities to execute complex and large size E&I projects successfully.": 
      "Commendable reputation in E&I construction has been built over years of successful execution of variety of large-scale projects for major companies in the Kingdom like smart meters project. With our long experience, expertise and highly competent team, we continue to enjoy the trust of our clients in our abilities to execute complex and large size E&I projects successfully.",
    
      "POWER CABLING": "POWER CABLING",

      "Signal & Control Cabling": "Signal & Control Cabling",
      "Fiber Optic Cabling": "Fiber Optic Cabling",
      "DCS System": "DCS System",
      "Power System": "Power System",
      "Lighting System": "Lighting System",
      "Earth and Lightning Protection System": "Earth and Lightning Protection System",
      "FNG Detection & Alarm System": "FNG Detection & Alarm System",
      "Instrument and Control System": "Instrument and Control System",
      "SMATV/CCTV Systems": "SMATV/CCTV Systems",
      "Sub-stations (HV/MV)": "Sub-stations (HV/MV)",

      // (Our Projects) MECHANICAL Constraction [ThirdProject]
      "MECHANICAL CONSTRUCTION": "MECHANICAL CONSTRUCTION",

      "Mechanical Construction is an integral part of our business, and is backed by experienced professionals having long construction projects experience. We have successfully completed number of projects of critical nature and execution timeline for customers like SAUDI ELECTRICITY COMPANY.And our experienced & competent team along with range of equipment required for carrying out mechanical construction works in an orderly manner.": 
      "Mechanical Construction is an integral part of our business, and is backed by experienced professionals having long construction projects experience. We have successfully completed number of projects of critical nature and execution timeline for customers like SAUDI ELECTRICITY COMPANY.And our experienced & competent team along with range of equipment required for carrying out mechanical construction works in an orderly manner.",
    
      "EQUIPMENTERECTION": "EQUIPMENTERECTION",

      "Piping around the equipment": "Piping around the equipment",
      "Valves, instruments & package equipment": "Valves, instruments & package equipment",
      "Fire hydrant circuits": "Fire hydrant circuits",
      "Pumps & turbines, compressor": "Pumps & turbines, compressor",
      "Steel structure erection": "Steel structure erection",
      "Heavy lifting": "Heavy lifting",
      "Industrial Building": "Industrial Building",

      // ###############################################
      // (Our Projects) Master Project
      "CIVIL CONSTRUCTION": "CIVIL CONSTRUCTION",
      "ELECTRICAL CONSTRUCTION": "ELECTRICAL CONSTRUCTION",
      "MECHANICAL CONSTRUCTION": "MECHANICAL CONSTRUCTION",

      "Our EQUIPMENT": "Our EQUIPMENT",
      "EQUIPMENT": "EQUIPMENT",

      "WHEEL LOADER": "WHEEL LOADER",
      "BACKHOLE": "BACKHOLE",
      "FORKLIFT TRUCK": "FORKLIFT TRUCK",

      "SKID STEER TRENCHER": "SKID STEER TRENCHER",
      "WATER TRUCK": "WATER TRUCK",
      "EXCAVATOR": "EXCAVATOR",

      "MIXER TRUCK": "MIXER TRUCK",
      "MOBILE ELEVATING ELECTRICITY": "MOBILE ELEVATING ELECTRICITY",
      "CRANE TRUCK": "CRANE TRUCK",

      "FLAT BED TRAILER": "FLAT BED TRAILER",
      "PICK UP CARS 4*4": "PICK UP CARS 4*4",
      "TRUCKS MERCEDES": "TRUCKS MERCEDES",

      "POLE DIGGER TRUCK": "POLE DIGGER TRUCK",
      "HEAVY HAULER": "HEAVY HAULER",





      // ###############################################
      // (Our Projec)
      "We are seeking to provide all the services related to electricity, and the Rawae Tzudan Co accredited to the Saudi Electricity Company and has all the human resources and logistics to carry out its work in accordance with the highest quality standards, our services are generally reflected in the following:": 
      "We are seeking to provide all the services related to electricity, and the Rawae Tzudan Co accredited to the Saudi Electricity Company and has all the human resources and logistics to carry out its work in accordance with the highest quality standards, our services are generally reflected in the following:",
    
      "Electrical contracting works": "Electrical contracting works",
      "Electrical maintenance": "Electrical maintenance",

      // services 1

      "The company works in electrical pressure, High and low pressure and the extension of ground cables and over head cables and the installation of electric transformers and electric over head columns of all kinds .": 
      "The company works in electrical pressure, High and low pressure and the extension of ground cables and over head cables and the installation of electric transformers and electric over head columns of all kinds .",

      "Supply and installation of all electrical materials from generators, transformers and electrical cables .": 
      "Supply and installation of all electrical materials from generators, transformers and electrical cables .",
    
      "The company has a fleet of equipment such as excavators, buccaneers, cars and air lines equipment such as cranes and baskets .": 
      "The company has a fleet of equipment such as excavators, buccaneers, cars and air lines equipment such as cranes and baskets .",

      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualify them to work on al) types of cables, transformers and Over head lines, as well as engineers are qualified to manage all electrical work .": 
      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualify them to work on al) types of cables, transformers and Over head lines, as well as engineers are qualified to manage all electrical work .",
    
      // Services 2

      "We have over ten years of experience in electrical maintenance contracts for distribution network lines up to 33 thousand kV .": 
      "We have over ten years of experience in electrical maintenance contracts for distribution network lines up to 33 thousand kV .",

      "They company work in the maintenance of electrical equipment for the Saudi electricity company .": 
      "They company work in the maintenance of electrical equipment for the Saudi electricity company .",

      "Maintenance of electrical meters , cables , transformers , overhead lines and excavations .": 
      "Maintenance of electrical meters , cables , transformers , overhead lines and excavations .",

      "Extend the ground cables by direct burial in the ground .": 
      "Extend the ground cables by direct burial in the ground .",

      "The company has a fleet of equipment such as excavators , buccaneers , cars and over head lines equipment such as cranes and baskets .": 
      "The company has a fleet of equipment such as excavators , buccaneers , cars and over head lines equipment such as cranes and baskets .",

      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualifies them to work on all types of cables, transformers and over head lines . Also, there are qualified engineers to manage all electrical works .": 
      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualifies them to work on all types of cables, transformers and over head lines . Also, there are qualified engineers to manage all electrical works .",
    



      // ################################################################################
      // (Our partner)
      "OUR CLIENTS": "OUR CLIENTS",
    
    }
  },
  ar: {
    translation: {
      // Navbar
      "Home": "الصفحه الرئيسيه",
      "Who We Are": "من نحن",
      "Our Projects": "مشاريعنا",
      "Our Servecies": "خدماتنا",
      "Our Success Partners": "عملاؤنا شركاء النجاح",

      // (Home) Hero Section
      "Discover Services": "اكتشف خدماتنا",
      "Explore RTC Company.": "اكتشف شركة روائع تزدان",

      // (Home) SOCIAL RESPONSIBILITY
      "SOCIAL RESPONSIBILITY": "المسؤليه الاجتماعيه",
      "Since our inception, we have committed ourselves to Rawae Tzudan Co which is dedicated to participating in all major projects aimed at social and economic development.":
       "منذ تأسيسنا، التزمنا تجاه شركة روائع تزدان التي تلتزم بالمشاركة في جميع المشاريع الكبرى التي تهدف إلى التنمية الاجتماعية والاقتصادية",
      
      "In addition to our profitability we aim to fulfill our full roles in this society to serve this country in response to something of its beauty and virtues. To the Kingdom of Saudi Arabia and assume our full responsibilities to contribute to the realization of the hopes and aspirations of this unique vision.": 
       "بالإضافة إلى ربحيتنا، فإننا نهدف إلى القيام بأدوارنا الكاملة في هذا المجتمع لخدمة هذا الوطن ردًا على شيء من جماله وفضائله. إلى المملكة العربية السعودية ونتحمل كامل مسؤولياتنا للمساهمة في تحقيق آمال وتطلعات هذه الرؤية الفريدة",

       "More About Us": "اعرف المزيد",

       "LEADING GLOBAL BUILDER AND DEVELOPER": "الشركة الرائدة في البناء و التطوير",

       // (Home) clints
      "OUR CLIENTS, OUR SUCCESS PARTNERS": "عملاؤونا، شركاء النجاح",
      "Trusted by world leading companies": "تثق بنا الشركات الرائدة",
    

      // ###############################################
      // (Who We Are) my-hero
      "RTC (with CR: 1010273881 ) Since its inception, the company has been a professional company in the field of electrical contracting, planning, installation and maintenance, and has been able to achieve a range of qualitative achievements at the level of activities as well as the number of customers acquired in Both government and private sectors, This success is due to the policy adopted and based on the overall quality standards and customer satisfaction.": 
      "RTC (بسجل تجاري: 1010273881) منذ تأسيسها كانت الشركة شركة محترفة في مجال المقاولات الكهربائية والتخطيط والتركيب والصيانة، واستطاعت تحقيق مجموعة من الإنجازات النوعية على مستوى الأنشطة وكذلك عدد العملاء المكتسبين في كل من القطاعين الحكومي والخاص، ويعود هذا النجاح إلى السياسة المعتمدة والمبنية على معايير الجودة الشاملة ورضا العملاء.",
      
      "The company has completed a large number of projects related to the field of electrical contracting. They have been able to obtain prestigious classifications, which were made by major projects in the Kingdom of Saudi Arabia. We are proud of our unique human resources, discipline, and strict responsibility within the framework of coordinated and integrated teams.": 
      "قامت الشركة بإنجاز عدد كبير من المشاريع المتعلقة بمجال المقاولات الكهربائية. وقد تمكنوا من الحصول على التصنيفات المرموقة التي قدمتها المشاريع الكبرى في المملكة العربية السعودية. نحن فخورون بمواردنا البشرية الفريدة والانضباط والمسؤولية الصارمة في إطار فرق منسقة ومتكاملة.",
    
      "The company has a large number of managers, engineers, technicians and workers of various nationalities with a focus on the Saudi energies, who have accumulated long experiences in the work related to electrical contracting Which contributed to enhancing their ability to complete the work according to the quality standards as is internationally recognized.": 
      "تضم الشركة عدد كبير من المديرين والمهندسين والفنيين والعمال من مختلف الجنسيات مع التركيز على الطاقات السعودية الذين تراكمت لديهم خبرات طويلة في الأعمال المتعلقة بالمقاولات الكهربائية مما ساهم في تعزيز قدرتهم على إنجاز العمل وفق معايير الجودة المعترف بها دوليا.",
    
      "About Us": "المزيد عنا",
      "Who We Are": "من نحن",

      // (Who We Are) content
      "VISION": "الرؤية",

      "Since our inception in the company in the year 2009, we designed to achieve our vision of comprehensive and built upon our strategic goals, and reflected in building the foundations of an integrated administrative and technical company, and available on the potential promising investment to get ratings that suit their ambitions and efforts, To become a primary reference for customers who wish to benefit from high quality services.": 
      "منذ بدايتنا في الشركة في عام 2009، حرصنا على تحقيق رؤيتنا الشاملة والمبنية على أهدافنا الإستراتيجية، والتي تنعكس في بناء أسس شركة إدارية وفنية متكاملة، ومتوفرة على الإمكانات الاستثمارية الواعدة التي يمكن الحصول عليها  تصنيفات تتناسب مع طموحاتهم وجهودهم، لتصبح مرجعًا أساسيًا للعملاء الذين يرغبون في الاستفادة من الخدمات عالية الجودة.",
    
      "MESSAGE": "الرسالة",

      "We carry in the company a message of economic and social foundations. We work hard to spread the values of quality in their administrative and technical dimensions within the companies and institutions that operate in the electrical contracting sector. In our social mission, we seek to support innovation and contribute to achieving a society that is more open to the Aspirations of citizens.": 
      "نحمل في الشركة رسالة الأسس الاقتصادية والاجتماعية . نعمل جاهدين على نشر قيم الجودة بأبعادها الإدارية والفنية داخل الشركات والمؤسسات العاملة في قطاع المقاولات الكهربائية.  نسعى في رسالتنا الاجتماعية إلى دعم الابتكار والمساهمة في تحقيق مجتمع أكثر انفتاحاً على تطلعات المواطنين.",
      
      "OBJECTIVES": "اهدافنا",

      "We aim to achieve a set of goals at the near and long term, and are reflected in :": 
      "نهدف إلى تحقيق مجموعة من الأهداف على المدى القريب والبعيد، وتتجلى في:",
    
      "- Achieve the highest quality standards and obtain international recognition certificates.": 
      "- تحقيق أعلى معايير الجودة والحصول على شهادات الاعتراف الدولية.",

      "- Achieve the best levels of communication with customers by developing the means available.": 
      "- تحقيق أفضل مستويات التواصل مع العملاء من خلال تطوير الوسائل المتاحة.",

      "- Training engineers , technicians , administrators and workers .": 
      "- تدريب المهندسين والفنيين والإداريين والعمال.",

      "- Spread our branches in the rest of the kingdom in the hope of expansion in the gulf region .": 
      "- نشر فروعنا في بقية أنحاء المملكة على أمل التوسع في منطقة الخليج.",
    
      // ###############################################
      // (Our Projects) Civil Constraction [FirstProjects]
      "CIVIL Construction": "البناء المدني",

      "By virtue of engagement & managing large-scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specifications for our customers. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.": 
      "بفضل المشاركة وإدارة مشاريع البناء واسعة النطاق، يصبح البناء المدني أمرًا لا مفر منه.  لقد أنجزنا بنجاح عددًا من العقود المدنية في مشاريع مختلفة مع الالتزام بالمواعيد النهائية الصارمة ومواصفات المشروع الصارمة لعملائنا.  لدينا فريق من ذوي الخبرة والكفاءة ومجموعة من معدات البناء اللازمة لتنفيذ أعمال البناء المدنية بسلاسة تجعلنا ناجحين",
    
      "All Services": "خدماتنا",
      "SURVEYING": "المسح الهندسي ",
      "Earthwork": "أعمال الحفر",

      "FOUNDATION": "الاساسات",
      "Super Structure": "الهيكل العلوي",
      "Industrial Building": "المباني الصناعية",
      "Architectural Finish": "التشطيبات المعمارية",
      "Pipe Rack": "حامل الأنابيب",
      "Warehouse": "المستودعات",
      "Plant Road": "الطرق النباتية",
      "Pavement": "الرصف",
      "Landscaping Work": "أعمال المناظر الطبيعية",


       // (Our Projects) ELECTRICAL Constraction [SecoundProject]
       "ELECTRICAL & INSTRUMENTATION CONSTRUCTION": "البناء الكهربائي و التركيبات الآلية",

       "Commendable reputation in E&I construction has been built over years of successful execution of variety of large-scale projects for major companies in the Kingdom like smart meters project. With our long experience, expertise and highly competent team, we continue to enjoy the trust of our clients in our abilities to execute complex and large size E&I projects successfully.": 
      "جدير بالثناء.  أنه قد  تم بناء سمعتنا في مجال البناء والتشييد على مدى سنوات من التنفيذ الناجح لمجموعة متنوعة من المشاريع واسعة النطاق للشركات الكبرى في المملكة مثل مشروع العدادات الذكية.  بفضل خبرتنا الطويلة ومهاراتنا  وفريقنا ذو الكفاءة العالية، نستمر في التمتع بثقة عملائنا في قدراتنا على تنفيذ مشاريع  البناء الكهربائي و التركيبات الآلية المعقدة والكبيرة الحجم بنجاح .",
    
      "POWER CABLING": "كابلات الطاقة",

      "Signal & Control Cabling": "كابلات الإشارة والتحكم",
      "Fiber Optic Cabling": "كابلات الألياف",
      "DCS System": "DCS نظام",
      "Power System": "نظام الطاقة",
      "Lighting System": "نظام الإضاءة",
      "Earth and Lightning Protection System": "نظام حماية الأرض والصواعق",
      "FNG Detection & Alarm System": "نظام كشف وإنذار FNG ",
      "Instrument and Control System": "نظام الأجهزة والتحكم",
      "SMATV/CCTV Systems": "SMATV / CCTV أنظمة",
      "Sub-stations (HV/MV)": "المحطات الفرعية (الجهد العالي/الجهد المتوسط)",
    
      // (Our Projects) MECHANICAL Constraction [ThirdProject]
      "MECHANICAL CONSTRUCTION": "البناء الميكانيكي",

      "Mechanical Construction is an integral part of our business, and is backed by experienced professionals having long construction projects experience. We have successfully completed number of projects of critical nature and execution timeline for customers like SAUDI ELECTRICITY COMPANY.And our experienced & competent team along with range of equipment required for carrying out mechanical construction works in an orderly manner.": 
      "البناء الميكانيكي هو جزء لا يتجزأ من أعمالنا، ويدعمه محترفون ذوو خبرة طويلة في مشاريع البناء.  لقد أنجزنا بنجاح عددًا من المشاريع ذات الطبيعة الحرجة والجدول الزمني للتنفيذ لعملاء مثل شركة الكهرباء السعودية.  ولدينا فريق من ذوي الخبرة والكفاءة بالإضافة إلى مجموعة من المعدات اللازمة لتنفيذ أعمال البناء الميكانيكية بطريقة منظمة.",
    
      "EQUIPMENTERECTION": "تركيب المعدات",

      "Piping around the equipment": "الأنابيب حول المعدات",
      "Valves, instruments & package equipment": "الصمامات والأدوات ومعدات التغليف",
      "Fire hydrant circuits": "أنظمة مكافحة الحرائق",
      "Pumps & turbines, compressor": "المضخات والتوربينات والضاغط",
      "Steel structure erection": "تركيب الهيكل الصلب",
      "Heavy lifting": "رفع الأثقال",
      "Industrial Building": "المباني الصناعية",


      // ###############################################
      // (Our Projects) Master Project
      "CIVIL CONSTRUCTION": "البناء المدني",
      "ELECTRICAL CONSTRUCTION": "البناء الكهربائي و التركيبات الآلية",
      "MECHANICAL CONSTRUCTION": "البناء الميكانيكي",

      "Our EQUIPMENT": "معداتنا",
      "EQUIPMENT": "المعدات",

      "WHEEL LOADER": "رافعة شوكية",
      "BACKHOLE": "ثقب الخلفي",
      "FORKLIFT TRUCK": "ونش",

      "SKID STEER TRENCHER": "خنادق انزلاقية التوجيه",
      "WATER TRUCK": "شاحنة مياه",
      "EXCAVATOR": "حفار",

      "MIXER TRUCK": "شاحنة خلاط",
      "MOBILE ELEVATING ELECTRICITY": "كهرباء رفع متنقلة",
      "CRANE TRUCK": "شاحنة رافعة",

      "FLAT BED TRAILER": "مقطورة مسطحة",
      "PICK UP CARS 4*4": "نصف نقل 4*4",
      "TRUCKS MERCEDES": "شاحنات مرسيدس",

      "POLE DIGGER TRUCK": "شاحنة حفار القطب",
      "HEAVY HAULER": "شاحنة نقل ثقيلة",



      // ###############################################
      // (Our Services)
      "We are seeking to provide all the services related to electricity, and the Rawae Tzudan Co accredited to the Saudi Electricity Company and has all the human resources and logistics to carry out its work in accordance with the highest quality standards, our services are generally reflected in the following:": 
      "نسعى لتقديم كافة الخدمات المتعلقة بالكهرباء، وشركة روائع تزدان معتمدة لدى الشركة السعودية للكهرباء وتمتلك كافة الموارد البشرية والخدمات اللوجستية للقيام بأعمالها وفق أعلى معايير الجودة، وتتجلى خدماتنا بشكل عام فيما يلي:",
    
      "Electrical contracting works": "أعمال المقاولات الكهربائية",
      "Electrical maintenance": "الصيانة الكهربائية",

      // Services 1
      "The company works in electrical pressure, High and low pressure and the extension of ground cables and over head cables and the installation of electric transformers and electric over head columns of all kinds .": 
      "تعمل الشركة في مجال  الجهد  الكهربائي والتوتر العالي والمنخفض وتمديد الكابلات الأرضية والكابلات  الهوائية وتركيب المحولات الكهربائية والأعمدة الكهربائية  الهوائية بجميع أنواعها.",

      "Supply and installation of all electrical materials from generators, transformers and electrical cables .": 
      "توريد وتركيب كافة المواد الكهربائية من مولدات ومحولات وكابلات كهربائية .",

      "The company has a fleet of equipment such as excavators, buccaneers, cars and air lines equipment such as cranes and baskets .": 
      "تمتلك الشركة أسطول من المعدات مثل الحفارات والبوكانيير والسيارات ومعدات الخطوط الجوية مثل الرافعات والسلال .",

      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualify them to work on al) types of cables, transformers and Over head lines, as well as engineers are qualified to manage all electrical work .": 
      "تمتلك شركة روائع  تزدان  كوادر فنية مدربة من ذوي الخبرة والشهادات التي تؤهلهم للعمل على كافة أنواع الكابلات والمحولات والخطوط الرئيسية وكذلك مهندسين مؤهلين لإدارة كافة الأعمال الكهربائية",
    
      // Services 2

      "We have over ten years of experience in electrical maintenance contracts for distribution network lines up to 33 thousand kV .": 
      "لدينا خبرة تزيد عن عشر سنوات في عقود الصيانة الكهربائية لخطوط شبكات التوزيع حتى 33 ألف كيلو فولت",

      "They company work in the maintenance of electrical equipment for the Saudi electricity company .": 
      "تعمل الشركة في صيانة المعدات الكهربائية لشركة الكهرباء السعودية.",

      "Maintenance of electrical meters , cables , transformers , overhead lines and excavations .": 
      "صيانةعدادات (أجهزة قياس) الكهرباء والكابلات والمحولات والخطوط الهوائية والحفريات",

      "Extend the ground cables by direct burial in the ground .": 
      "مد الكابلات الأرضية عن طريق الدفن المباشر في الأرض ",

      "The company has a fleet of equipment such as excavators , buccaneers , cars and over head lines equipment such as cranes and baskets .": 
      "تمتلك الشركة أسطول من المعدات مثل الحفارات والبوكانيير والسيارات ومعدات الخطوط الهوائية مثل الرافعات والسلال . ",

      "Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualifies them to work on all types of cables, transformers and over head lines . Also, there are qualified engineers to manage all electrical works .": 
      "تمتلك شركة روائع  تزدان كوادر فنية مدربة ذات خبرة وشهادات تؤهلهم للعمل على جميع أنواع الكابلات  والمحولات والخطوط الرئيسية ، كما يوجد مهندسين مؤهلين لإدارة كافة الأعمال الكهربائية",
    
      


      // ###############################################
      // (Our partner)
      "OUR CLIENTS": "عملاؤنا",
    }
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
