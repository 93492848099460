import './style_MasterProject.css';
import img_01 from '../../../Home Images/01.png';
import img_02 from '../../../Home Images/02.png';
import img_03 from '../../../Home Images/03.png';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

import img_04 from '../../../Home Images/04.png';
import img_05 from '../../../Home Images/05.png';
import img_06 from '../../../Home Images/06.png';

import img_07 from '../../../Home Images/07.png';
import img_08 from '../../../Home Images/08.png';
import img_09 from '../../../Home Images/09.png';

import img_10 from '../../../Home Images/010.png';
import img_11 from '../../../Home Images/011.png';
import img_12 from '../../../Home Images/012.png';

import img_13 from '../../../Home Images/013.png';
import img_14 from '../../../Home Images/014.png';
import img_15 from '../../../Home Images/015.png';

import img_16 from '../../../Home Images/016.png';
import img_17 from '../../../Home Images/017.png';
import { Link } from 'react-router-dom';



function MasterProject() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='master-our-project'>
                <div className='my-hero'>
                    <div className='my-para'>
                        <motion.p>
                                {t('By virtue of engagement & managing large-scale construction projects, Civil Construction becomes inevitable. We have successfully completed number of civil contracts on various projects meeting the stringent deadlines and strict project specifications for our customers. Our experienced & competent team and a range of construction equipment required for carrying out civil construction works smoothly makes us successful.')}
                        </motion.p>
                    </div>

                    <div className='my-img'>
                        <Link to='/CIVIL-Construction'>
                            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2}} className='img-one'>
                                <img src={img_01}/>
                                <div className='content'>
                                    <br />
                                    <br />
                                    <h1 style={{fontFamily: 'GE Flow'}}>{t('CIVIL CONSTRUCTION')}</h1>
                                    <i className="fa-solid fa-person-digging"></i>
                                </div>
                            </motion.div>
                        </Link>

                        <Link to='/ELECTRICAL-Construction'>
                            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2, delay: 0.5}} className='img-one'>
                                <img src={img_02}/>
                                <div className='content'>
                                    <h1 style={{fontFamily: 'GE Flow'}}>{t('ELECTRICAL CONSTRUCTION')}</h1>
                                    <i className="fa-solid fa-bolt"></i>
                                </div>
                            </motion.div>
                        </Link>

                        <Link to='/MECHANICAL-Construction'>
                            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2, delay: 1}} className='img-one'>
                                <img src={img_03}/>
                                <div className='content'>
                                    <h1 style={{fontFamily: 'GE Flow'}}>{t('MECHANICAL CONSTRUCTION')}</h1>
                                    <i className="fa-solid fa-gear"></i>
                                </div>
                            </motion.div>
                        </Link>
                        
                        
                    </div>
                    <div className='content'>
                        

                        {/* <div className='head'>
                            <h1>{t('MECHANICAL CONSTRUCTION')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Projects')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('MECHANICAL CONSTRUCTION')}</h5>
                            </div>
                        </div> */}

                        
                        
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    <div className='small-title'>
                        <h5>
                            <span></span>
                            {t('Our EQUIPMENT')}
                        </h5>
                    </div>

                    <div className='big-title'>
                        <h1 style={{fontFamily: 'GE Flow'}}>{t('EQUIPMENT')}</h1>
                    </div>

                    <div className='all-EQUIPMENT'>
                        {/* Line 1 */}
                        <div className='card'>
                            <h2 className='WHEEL-LOADER'>{t('WHEEL LOADER')}</h2>
                            <img src={img_04}/>
                        </div>

                        <div className='card'>
                            <h2>{t('BACKHOLE')}</h2>
                            <img src={img_05}/>
                        </div>

                        <div className='card'>
                            <h2 className='FORKLIFT-TRUCK'>{t('FORKLIFT TRUCK')}</h2>
                            <img src={img_06}/>
                        </div>

                        {/* Line 2 */}
                        <div className='card'>
                            <h2>{t('SKID STEER TRENCHER')}</h2>
                            <img src={img_07}/>
                        </div>
                        <div className='card'>
                            <h2>{t('WATER TRUCK')}</h2>
                            <img src={img_08}/>
                        </div>
                        <div className='card'>
                            <h2>{t('EXCAVATOR')}</h2>
                            <img src={img_09}/>
                        </div>

                        {/* Line 3 */}
                        <div className='card'>
                            <h2>{t('MIXER TRUCK')}</h2>
                            <img src={img_10}/>
                        </div>
                        <div className='card'>
                            <h2 className='MOBILE-ELEVATING-ELECTRICITY'>{t('MOBILE ELEVATING ELECTRICITY')}</h2>
                            <img src={img_11}/>
                        </div>
                        <div className='card'>
                            <h2>{t('CRANE TRUCK')}</h2>
                            <img src={img_12}/>
                        </div>

                        {/* Line 4 */}
                        <div className='card'>
                            <h2 className='FLAT-BED-TRAILER'>{t('FLAT BED TRAILER')}</h2>
                            <img src={img_13}/>
                        </div>
                        <div className='card'>
                            <h2 className='MOBILE-ELEVATING-ELECTRICITY'>{t('PICK UP CARS 4*4')}</h2>
                            <img src={img_14}/>
                        </div>
                        <div className='card'>
                            <h2 className='TRUCKS-MERCEDES'>{t('TRUCKS MERCEDES')}</h2>
                            <img src={img_15}/>
                        </div>

                        {/* Line 5 */}
                        <div className='card'>
                            <h2 className='FLAT-BED-TRAILER'>{t('POLE DIGGER TRUCK')}</h2>
                            <img src={img_16}/>
                        </div>
                        <div className='card'>
                            <h2 className='MOBILE-ELEVATING-ELECTRICITY'>{t('HEAVY HAULER')}</h2>
                            <img src={img_17}/>
                        </div>


                    </div>

                   

                </div>
            </div>
        </>
    );
};


export default MasterProject;