import './style_OurPartner.css';

import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

import our_partner_img from '../../Home Images/ourPartner.png';

import company_1_img from '../../Home Images/company-01.png';
import company_2_img from '../../Home Images/company-02.png';
import company_3_img from '../../Home Images/company-03.png';
import company_4_img from '../../Home Images/company-04.png';

function OurPartner() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='our-partner'>
                <div className='my-hero'>
                    <div className='my-img'>
                        <img src={our_partner_img}/>
                        
                    </div>
                    <div className='content'>
                        

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}>{t('Our Success Partners')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Success Partners')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('OUR CLIENTS')}</h5>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>


                    <div className='clints'>
                    {/* <div className='tow-circle'>
                        
                    </div> */}
                    
                        <div className='content'> 
                            <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='left'>
                                    <h5>
                                        <span></span>
                                        {t('OUR CLIENTS, OUR SUCCESS PARTNERS')}
                                    </h5>
                                </motion.div>

                                <motion.div initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1}} className='right'>
                                    <h1>{t('Trusted by world leading companies')}</h1>
                                </motion.div>
                        </div>
                    
                        <div className='logos'>
                            <motion.img src={company_1_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.3}} />
                            <motion.img src={company_2_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.6}}/>
                            <motion.img src={company_3_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 0.9}}/>
                            <motion.img src={company_4_img} initial={{opacity: 0, scale: 0}} whileInView={{opacity: 1, scale: 1}} transition={{duration: 1, delay: 1.2}}/>
                        </div>

                        {/* <span className='circle'></span> */}
                </div>

                    

                </div>
            </div>
        </>
    );
};


export default OurPartner;