import './style_SecoundProjects.css';
import ELECTRICAL_img from '../../../Home Images/ELECTRICAL Consrtuction.png';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';
import inside_ELECTRICAL_img from '../../../Home Images/ELECTRICAL.png';

function SecoundProject() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='secound-project'>
                <div className='my-hero'>
                    <div className='my-img'>
                        <img src={ELECTRICAL_img}/>
                        
                    </div>
                    <div className='content'>
                        

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}>{t('ELECTRICAL & INSTRUMENTATION CONSTRUCTION')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Projects')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('ELECTRICAL & INSTRUMENTATION CONSTRUCTION')}</h5>
                            </div>
                        </div>

                        <motion.p initial={{x: '-120%', opacity: 0}} animate={{x: '0%', opacity: 1}} transition={{duration: 1}}>
                            {t('Commendable reputation in E&I construction has been built over years of successful execution of variety of large-scale projects for major companies in the Kingdom like smart meters project. With our long experience, expertise and highly competent team, we continue to enjoy the trust of our clients in our abilities to execute complex and large size E&I projects successfully.')}
                        </motion.p>
                        
                    </div>
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    <div className='blok'>
                        <div className='left'>
                            <motion.h1 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}}>{t('All Services')}</motion.h1>

                            
                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}} className='services'>
                                <i className="fa-solid fa-circle"></i>
                                <h3>{t('POWER CABLING')}</h3>
                            </motion.div>

                            <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5, delay: 1}}>
                                <p>-{t('Signal & Control Cabling')}</p>
                                <br />
                                <p>-{t('Fiber Optic Cabling')}</p>
                                <br />
                                <p>-{t('DCS System')}</p>
                                <br />
                                <p>-{t('Power System')}</p>
                                <br />
                                <p>-{t('Lighting System')}</p>
                                <br />
                                <p>-{t('Earth and Lightning Protection System')}</p>
                                <br />
                                <p>-{t('FNG Detection & Alarm System')}</p>
                                <br />
                                <p>-{t('Instrument and Control System')}</p>
                                <br />
                                <p>-{t('SMATV/CCTV Systems')}</p>
                                <br />
                                <p>-{t('Sub-stations (HV/MV)')}</p>
                            </motion.div>
                            
                        </div>

                        

                        <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration: 0.5}} className='right'>
                            <img src={inside_ELECTRICAL_img}/>
                        </motion.div>
                    </div>

                </div>
            </div>
        </>
    );
};


export default SecoundProject;