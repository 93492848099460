// import logo from './logo.svg';
// import './App.css';

import { useEffect, useState } from "react";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar/Navbar";
import OurPartner from "./Components/Our Partner/OurPartner";
import FirstProjects from "./Components/Our Projects/FirstProjects";
import MasterProject from "./Components/Our Projects/Master Page/MasterProject";
import SecoundProject from "./Components/Our Projects/Secound Projects/SecoundProject";
import ThirdProject from "./Components/Our Projects/Third Project/ThirdProject";
import OurServices from "./Components/Our Services/OurServices";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";
import WhoWeAre from "./Components/Who We Are/WhoWeAre";
import './i18n'; // Import i18n.js
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Navbar />

      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>

        <Route path="/WhoWeAre" element={<WhoWeAre></WhoWeAre>}></Route>

        <Route path="/Our-Projects" element={<MasterProject></MasterProject>}></Route>
        <Route path="/CIVIL-Construction" element={<FirstProjects></FirstProjects>}></Route>
        <Route path="/ELECTRICAL-Construction" element={<SecoundProject></SecoundProject>}></Route>
        <Route path="/MECHANICAL-Construction" element={<ThirdProject></ThirdProject>}></Route>

        <Route path="/Our-Services" element={<OurServices></OurServices>}></Route>

        <Route path="/Our-Partner" element={<OurPartner></OurPartner>}></Route>
      </Routes>

      {/* <Home /> */}
      <Footer />
    </div>
  );

  
}

export default App;
