import './style_OurServices.css';
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

import servcies_1_img from '../../Home Images/Services-1.png';
import servcies_2_img from '../../Home Images/Services-2.png';
import vector_img from '../../Home Images/vector-about.png';

function OurServices() {
    const { t, i18n } = useTranslation();

    return(
        <>
            <div className='our-services'>
                <div className='my-hero'>
                    <div className='my-para'>
                        <motion.p>
                                {t('We are seeking to provide all the services related to electricity, and the Rawae Tzudan Co accredited to the Saudi Electricity Company and has all the human resources and logistics to carry out its work in accordance with the highest quality standards, our services are generally reflected in the following:')}
                        </motion.p>
                    </div>

                    <div className='my-img'>
                        <a href='#MyFirst'>
                            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2}} className='img-one'>
                                <img src={servcies_1_img}/>
                                <div className='content'>
                                    <h1 style={{fontFamily: 'GE Flow'}}>{t('Electrical contracting works')}</h1>
                                    <i className="fa-solid fa-bolt"></i>
                                </div>
                            </motion.div>
                        </a>

                        <a href='#MySecound'>
                            <motion.div initial={{opacity: 0}} animate={{opacity: 1}} transition={{duration: 2, delay: 0.5}} className='img-one'>
                                <img src={servcies_2_img}/>
                                <div className='content'>
                                    <h1 style={{fontFamily: 'GE Flow'}}>{t('Electrical maintenance')}</h1>
                                    <i className="fa-solid fa-bolt-lightning"></i>
                                </div>
                            </motion.div>
                        </a>
                        
                        
                    </div>
                    
                </div>

                <div className='main-content'>
                    <span className='circle'></span>

                    {/* First Services */}
                    <div id='MyFirst' className='blok'>

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}><span>1.</span> {t('Electrical contracting works')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Servecies')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('Electrical contracting works')}</h5>
                            </div>
                        </div>


                        <div className='all-content'>
                            <div className='my-left'>
                                <div className='my-content'>
                                    <p><span>1.</span> {t('The company works in electrical pressure, High and low pressure and the extension of ground cables and over head cables and the installation of electric transformers and electric over head columns of all kinds .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>2.</span> {t('Supply and installation of all electrical materials from generators, transformers and electrical cables .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>3.</span> {t('The company has a fleet of equipment such as excavators, buccaneers, cars and air lines equipment such as cranes and baskets .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>4.</span> {t('Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualify them to work on al) types of cables, transformers and Over head lines, as well as engineers are qualified to manage all electrical work .')}</p>
                                </div>
                            </div>

                            <div className='my-right'>
                                <img src={vector_img}/>
                            </div>
                        </div>
                    </div>


                    {/* Secound Services */}
                    <div id='MySecound' className='blok'>

                        <div className='head'>
                            <h1 style={{fontFamily: 'GE Flow'}}><span>2. </span>{t('Electrical maintenance')}</h1>
                            <hr />
                            <div className='title'>
                                <h5>{t('Our Servecies')}</h5>
                                <i className="fa-solid fa-caret-right"></i>
                                <h5>{t('Electrical maintenance')}</h5>
                            </div>
                        </div>


                        <div className='all-content'>
                            <div className='my-left'>
                                <div className='my-content'>
                                    <p><span>1.</span> {t('We have over ten years of experience in electrical maintenance contracts for distribution network lines up to 33 thousand kV .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>2.</span> {t('They company work in the maintenance of electrical equipment for the Saudi electricity company .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>3.</span> {t('Maintenance of electrical meters , cables , transformers , overhead lines and excavations .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>4.</span> {t('Extend the ground cables by direct burial in the ground .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>5.</span> {t('The company has a fleet of equipment such as excavators , buccaneers , cars and over head lines equipment such as cranes and baskets .')}</p>
                                </div>

                                <div className='my-content'>
                                    <p><span>6.</span> {t('Rawae Tuzdan Co Have trained technical staff with the experience and certificates that qualifies them to work on all types of cables, transformers and over head lines . Also, there are qualified engineers to manage all electrical works .')}</p>
                                </div>
                            </div>

                            <div className='my-right'>
                                <img src={vector_img}/>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </>
    );
};


export default OurServices;